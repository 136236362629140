import { Controller } from "stimulus"

export default class extends Controller {
  expand() {
    this.element.classList.add('expanded')
  }

  collapse() {
    this.element.classList.remove('expanded')
  }
}
