import { Controller } from "stimulus"
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/mobilefriendly'

export default class extends Controller {
  connect() {
    this.broadcasting = true
    this.picker = new Litepicker({
      element: this.element,
      elementEnd: document.getElementById(this.element.dataset.elementEnd),
      startDate: this.element.value,
      endDate: document.getElementById(this.element.dataset.elementEnd).value,
      singleMode: false,
      allowRepick: true,
      minDate: new Date(),
      lang: document.querySelector('body').dataset.userLocale,
      maxDays: 21,
      minDays: 2,
      resetButton: () => {
        let btn = document.createElement('button');
        btn.setAttribute('class', "reset-button");
        let icon = document.createElement("i");
        icon.setAttribute('class', "bi bi-trash");
        btn.appendChild(icon);

        return btn;
      },
      tooltipText: {
        one: 'night',
        other: 'nights'
      },
      tooltipNumber: (totalDays) => {
        return totalDays - 1;
      },
      numberOfMonths: 2,
      numberOfColumns: 2,
      plugins: ['mobilefriendly']
    })

    this.picker.on('clear:selection', (date1, date2) => {
      if (this.broadcasting) {
        this.element.dispatchEvent(new CustomEvent('litepicker:selection-cleared'))
      }
    });

    this.picker.on('selected', (date1, date2) => {
      if (this.broadcasting) {
        this.element.dispatchEvent(new CustomEvent('litepicker:selected', { detail: { checkInAt: date1, checkOutAt: date2 } }))
      }
    });
  }
}
