import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input'

import 'intl-tel-input/build/css/intlTelInput.css'

export default class extends Controller {
  static targets = [ "label", "input" ]

  connect() {
    // NOTE: can't use builtin hiddenInput as this works only on submit and with valid numbers
    this.iti = intlTelInput(this.labelTarget, {
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js'
      // hiddenInput: this.inputTarget.name
    })
  }

  updateNumber() {
    if (this.iti.isValidNumber()) {
      this.inputTarget.value = this.iti.getNumber()
    } else if (this.labelTarget.value != '') {
      this.inputTarget.value = ''
      new Toast({ type: 'error', title: 'Unexpected Error', content: 'Contact number is invalid'})
    } else {
      this.inputTarget.value = ''
    }
  }
}
