import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.mapController = this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='map']"), "map")
    this.mapController.reloadPropertyMarkers()

    document.querySelector('[data-grid-count]').innerHTML = `${this.element.querySelectorAll('.card-property').length}`

    if (this.element.dataset.roombossFiltered == 'true') {
      this.element.querySelectorAll('.card-property').forEach(element => element.classList.add('rb-filtered'))

      // Show/Hide available room types & add dynamic pricing
      this.propertyPricing = JSON.parse(this.element.dataset.propertyPricing)
      Object.entries(this.propertyPricing).forEach((entry) => {
        const [index, hotelData] = entry
        const propertyCard = this.element.querySelector(`.card-property[data-hotel-id='${hotelData.hotel_id}']`)
        const propertyPrice = propertyCard.querySelector('[data-property-price]')

        propertyPrice.innerHTML = hotelData.formatted_minimum_price
        propertyPrice.setAttribute('data-price', hotelData.minimum_price)
        propertyPrice.setAttribute('data-controller', 'currency')
        propertyCard.querySelector('.property-price button').classList.add('d-block')
        propertyCard.querySelector('.js-button-request-price').classList.add('d-none')

        Object.entries(hotelData.room_type_pricing).forEach((roomEntry) => {
          const [index, roomData] = roomEntry
          const roomItem = propertyCard.querySelector(`[data-room-type-id='${roomData.room_type_id}']`)

          if (roomItem) {
            roomItem.querySelectorAll('[data-room-type-price]').forEach(element => element.innerHTML = roomData.formatted_price)
            roomItem.querySelectorAll('[data-room-type-price]').forEach(element => element.setAttribute('data-price', roomData.price))
            roomItem.classList.add('available')
          } else {
            console.warn(`[${hotelData.hotel_id}] Room not present - ${roomData.room_type_id}`)
          }
        })
      })
    }
  }

  teardown() {
    this.element.setAttribute('data-controller', null)
    document.querySelector('[data-grid-count]').innerHTML = "<div class='spinner-border property-spinner' role='status'></div>"
  }

  displayLoader(event) {
    if (event.target.id == "grid_form") {
      document.querySelector('[data-grid-count]').innerHTML = "<div class='spinner-border property-spinner' role='status'></div>"
    }
  }
}
