import { Controller } from "stimulus"
// TODO: Maybe use alternative? Really large library here
// switch to https://plyr.io/
import videojs from 'video.js/dist/alt/video.core.novtt.min.js'

export default class extends Controller {
  // static targets = ['player']
  connect() {
    let videoPlayer = videojs(this.element, {
      controls: true,
      // playbackRates: [0.5, 1, 2],
      autoplay: false,
      fluid: true,
      controlBar: {
        'pictureInPictureToggle': false
      }
    })
    videoPlayer.addClass('video-js')
    videoPlayer.addClass('custom')
  }
}
