const EventEmitter = require('events');

class ShoppingCart extends EventEmitter {
  constructor() {
    super()
    this.items = {}
    this.totalPrice = 0
    this.itemCount = 0
  }

  addItem(item) {
    var id = Date.now().toString()
    item.id = id
    this.items[id] = item
    this.totalPrice += item.price
    this.itemCount++

    this.emit('item-added', item)
  }

  removeItem(id) {
    if (this.items.hasOwnProperty(id)) {
      var item = this.items[id]
      delete this.items[id]
      this.totalPrice -= item.price
      this.itemCount--

      this.emit('item-removed', item)
    }
  }

  isEmpty() {
    return this.itemCount < 1
  }
};

window.ShoppingCart = ShoppingCart
export default ShoppingCart
