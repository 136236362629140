import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "results" ]

  connect() {
    this.previouslySubmitted = false
  }

  handleTurboSubmissionStart(event) {
    if (this.formTarget.querySelector('[name="checkin_at"]').value == '') {
      new Toast({type: 'error', title: I18n.t('forms.generic_error.title'), content: I18n.t("forms.room_type_search.blank_dates") })
      event.detail.formSubmission.stop()
      event.preventDefault()
    } else {
      this.resultsTarget.classList.add('loading')
      Array.from(this.formTarget.querySelectorAll('input, select, button')).forEach(element => {
        element.disabled = true
      })
    }
  }

  handleTurboSubmissionEnd(event) {
    this.resultsTarget.classList.remove('loading')
    Array.from(this.formTarget.querySelectorAll('input, select, button')).forEach(element => {
      element.disabled = false
    })

    if (event.detail.success) {
      this.previouslySubmitted = true
      window.shoppingCart = new ShoppingCart
      if (document.contains(document.querySelector('#modal-booking'))) {
        document.querySelector('#modal-booking').remove()
      }
    } else {
      if (this.formTarget.querySelector('[name="checkin_at"]').value != "") {
        new Toast({type: 'error', title: I18n.t('forms.generic_error.title'), content: 'An unexpected error occurred. Please try again later.' })
      }
    }
  }

  eagerSubmit() {
    if (this.previouslySubmitted) {
      this.formTarget.querySelector('button').click()
    }
  }
}
