import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      spaceBetween: 30,
      slidesPerView: 1,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // }// ,
      pagination: {
        el: ".swiper-pagination" //,
        // type: "fraction",
      },
      // //slidesPerView: 'auto',
      // loop: true,
      // // loopedSlides: 22,
      // // loopAdditionalSlides: 10,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true
      }
      // breakpoints: {
      //   640: {
      //     slidesPerView: 4,
      //     spaceBetween: 40
      //   }
      // }
    });
  }
}
