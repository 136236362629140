import { Controller } from "stimulus"

export default class extends Controller {
  // Note: Almost duplicate of form-select-alias
  forward() {
    // Return early if event has been forwarded
    if ((typeof event.detail != "undefined") && event.detail.forward) {
      return;
    }

    let formElement = document.getElementsByName(this.element.dataset.formElement)[0];
    formElement.value = this.element.value

    formElement.form.querySelector('button').click()

    let aliases = document.querySelectorAll(`[data-controller='form-text-alias'][data-form-element='${this.element.dataset.formElement}']`)
    Array.from(aliases).filter(alias => alias != this.element).forEach(alias => {
      alias.value = this.element.value
      alias.dispatchEvent(new CustomEvent('change', { detail: { forward: true } }))
    })
  }
}
