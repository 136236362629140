import { Controller } from "stimulus"

export default class extends Controller {
  update() {
    document.querySelectorAll('[data-activity]').forEach(element => {
      if (this.element.value == "all") {
        // All selected - show all
        element.classList.remove('d-none')
      } else if (element.getAttribute(`data-activity-filter-${this.element.value}`) == null) {
        // Current element does not have selected attribute - hide it
        element.classList.add('d-none')
      } else {
        // Current element does have selected attribute - show it
        element.classList.remove('d-none')
      }
    })
  }
}
