import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bookButton", "lineItemTemplate", "list", "priceLabel" ]

  connect() {
    window.shoppingCart.on('item-added', (item) => {
      this.addItem(item)
    })

    window.shoppingCart.on('item-removed', (item) => {
      this.removeItem(item)
    })
  }

  addItem(item) {
    this.listTarget.insertAdjacentHTML('beforeend', this.lineItemTemplateTarget.innerHTML)
    var lineItemElement = Array.from(this.listTarget.querySelectorAll('.shopping-cart-line-item')).pop()

    lineItemElement.dataset.id = item.id
    lineItemElement.querySelector('.shopping-cart-line-item--title').innerHTML = item.roomTypeTitle
    lineItemElement.querySelector('.shopping-cart-line-item--subtitle').innerHTML = item.title
    lineItemElement.querySelector('.shopping-cart-line-item--price span').dataset.price = item.price
    lineItemElement.querySelector('.shopping-cart-line-item--price span').dispatchEvent(new CustomEvent('currency:recalculate'))
    lineItemElement.querySelector('.shopping-cart-line-item--guests span').innerHTML = item.guests

    this.updatePrice()
    this.toggleBookButton()
  }

  handleRemovalClick(event) {
    window.shoppingCart.removeItem(event.currentTarget.parentElement.dataset.id)
  }

  removeItem(item) {
    var lineItemElement = this.listTarget.querySelector(`[data-id='${item.id}']`)

    this.updatePrice()
    this.toggleBookButton()
    lineItemElement.remove()
  }

  openBookingModal(event) {
    const modal = document.querySelector('#modal-booking')
    const instance = bootstrap.Modal.getInstance(modal)
    if (instance) {
      instance.show()
    } else {
      new bootstrap.Modal(modal).show()
    }
  }

  toggleBookButton() {
    if (window.shoppingCart.isEmpty()) {
      this.bookButtonTarget.disabled = true
    } else {
      this.bookButtonTarget.disabled = false
    }
  }

  updatePrice() {
    this.priceLabelTarget.dataset.price = window.shoppingCart.totalPrice
    this.priceLabelTarget.dispatchEvent(new CustomEvent('currency:recalculate'))
  }
}
