import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "rateLabel", "addButton", "addButtonMobile", "titleLabel" ]

  addRate(event) {
    var rate = {
      ratePlanId: this.element.dataset.id,
      roomTypeId: this.roomTypeController.element.dataset.id,
      roomTypeTitle: this.roomTypeController.titleLabelTarget.innerHTML,
      title: this.titleLabelTarget.innerHTML,
      price: parseInt(this.rateLabelTarget.dataset.price),
      guests: this.roomTypeController.guestInputTarget.value
    }

    window.shoppingCart.addItem(rate)
  }

  disableRate() {
    this.addButtonTarget.disabled = true
    this.addButtonMobileTarget.disabled = true
  }

  enableRate() {
    this.addButtonTarget.disabled = false
    this.addButtonMobileTarget.disabled = false
  }

  get roomTypeController() {
    return this.application.getControllerForElementAndIdentifier(this.element.closest('.available-room-type-wrapper'), "available-room-type")
  }
}
