import { Controller } from "stimulus"
import mapboxgl from '!mapbox-gl'
var Mustache = require('mustache');

// TODO: Re-render markers when moving and/or after a search
export default class extends Controller {
  connect() {
    this.accessCode = 'pk.eyJ1IjoicGF0cmlja2xpbmRzYXkiLCJhIjoiY2p6aHd4am1mMGI0YjNvbngwMW8xdm1jMiJ9.MiLAC_Vh_wO0ugv3M7wa1A'
    this.lifts = JSON.parse(this.element.dataset.lifts)
    this.restaurants = JSON.parse(this.element.dataset.restaurants)
    mapboxgl.accessToken = this.accessCode
    this.styleUrl = 'mapbox://styles/patricklindsay/ck0big4ms0poc1cp28a1nc3rv'
    this.centerCoords = [140.7069043, 42.8601737]
    this.propertyPopupTemplate = document.querySelector('#property_popup_template').innerHTML
    this.restaurantPopupTemplate = document.querySelector('#restaurant_popup_template').innerHTML
    this.restaurantsZoomDependant = true
    this.restaurantsVisible = false
    this.minimumRestaurantZoom = 16
    this.propertyMarkers = new Map()

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.styleUrl,
      center: this.centerCoords,
      zoom: 14,
      minZoom: 6,
      maxZoom: 20,
      attributionControl: false
    })
    this.map.addControl(new mapboxgl.NavigationControl())
    this.map.addControl(new mapboxgl.AttributionControl({
      compact: true
    }))

    this._setupLifts()
    this._setupRestaurants()

    document.addEventListener('turbo:before-cache', () => {
      this._removeActivePopup()
    });
  }

  open() {
    // mapCenter = @map.getCenter()
    this.map.resize()
    // @map.flyTo(center: mapCenter)
  }

  reloadPropertyMarkers() {
    this._reloadProperties()
    this._setupPropertyLayer()

    // TODO perhaps need to check if its manual mode and just loaded in order to center of Niseko regardless of marker location for main SRP
    // @_centerMapOnMarkers(data) if data.count > 0
  }

  flyToProperty(propertyId) {
    let offset = [0, -100]
    if (!this._isViewingOnSmallScreen()) {
      offset = [0, 0]
    }
    propertyId = parseInt(propertyId)

    this.map.flyTo({ center: this.propertyMarkers.get(propertyId).source.geometry.coordinates, offset: offset, zoom: 16 })
    this.map.once('moveend', () => {
      this._openPropertyMarkerPopup(this.propertyMarkers.get(propertyId).source)
    })
  }

  _setupPropertyLayer() {
    if (this.map.getSource('properties')) {
      this._removeActivePopup()
      this._removeMarkers()
      this.map.removeLayer('property-clusters')
      this.map.removeLayer('property-clusters-count')
      this.map.removeSource('properties')
    }

    this.map.addSource("properties", {
      type: "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": this.properties
      },
      cluster: true,
      clusterMaxZoom: 13
    })

    this.map.addLayer({
      id: "property-clusters",
      type: "circle",
      source: "properties",
      filter: ["has", "point_count"],
      paint: {
        'circle-opacity': 0.9,
        "circle-color": "#CB7C88",
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          3,
          25,
          5,
          30,
          10,
          40
        ]
      }
    })

    this.map.addLayer({
      id: 'property-clusters-count',
      type: 'symbol',
      source: 'properties',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {
        "text-color": "#ffffff"
      }
    })

    // Handle zooming into clusters on click
    this.map.on('click', 'property-clusters', (event) => {
      const features = this.map.queryRenderedFeatures(event.point, {
        layers: ['property-clusters']
      })
      const clusterId = features[0].properties.cluster_id

      this.map.getSource('properties').getClusterExpansionZoom(clusterId, (err, zoom) => {
        // return if err
        this.map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom
        })
      })
    })

    // Handle cursor icon when hovering and unhovering over property clusters
    this.map.on('mouseenter', 'property-clusters', () => {
      this.map.getCanvas().style.cursor = 'pointer'
    })
    this.map.on('mouseleave', 'property-clusters', () => {
      this.map.getCanvas().style.cursor = ''
    })

    // Render all markers & hide them - This is so users can fly to markers behind a cluster
    this.properties.forEach((propertySource, index) => {
      // console.log("Creating Property Marker #{propertySource.properties.id}")
      const propertyElement = document.createElement('div')
      propertyElement.className = `map-marker-property ${propertySource.properties.type} d-none`

      // Add Marker to Map
      new (mapboxgl.Marker)(propertyElement).setLngLat(propertySource.geometry.coordinates).addTo(this.map)

      propertyElement.addEventListener('click', (event) => {
        this._handlePropertyMarkerClick(propertySource)
        event.stopPropagation()
      })

      this.propertyMarkers.set(propertySource.properties.id, { element: propertyElement, source: propertySource })
    })

    // Normally would wait to until source is loaded but for some reason this is sometimes never loading. Although we know its loaded because it is inline (!?)
    this.map.on('moveend', () => {
      this._refreshPropertyClustering()
    })
    this._refreshPropertyClustering()

    // // Not doing this as sometimes source wouldn't be marked as loaded even though it is inline
    // this.map.on('data', (event) => {
    //   // # console.log("SourceId: #{event.sourceId} - isSourceLoaded: #{event.isSourceLoaded}")
    //   if (event.sourceId == 'properties' && event.isSourceLoaded) {
    //     this.map.on('moveend', this._refreshPropertyClustering)
    //     this._refreshPropertyClustering()
    //   }
    // })
  }


  _reloadProperties() {
    const collection = []

    document.querySelectorAll('#property_list .card-property').forEach(function (element) {
      let property = JSON.parse(element.dataset.mapData)

      // # Set pricing if available
      // property_pricing = response.property_pricing[property.enquiry_url]
      // if property_pricing && !property.vendor_search_only
      //   property.button_text = I18n.t('actions.choose_room')
      //   property.url += '#book'
      //   property.minimum_price = property_pricing.minimum_price
      //   property.formatted_minimum_price = property_pricing.formatted_minimum_price

      collection.push(property)
    })

    this.properties = collection
  }

  _setupLifts() {
    this.map.on('load', () => {
      this.map.addLayer({
        "id": "lifts",
        "type": "line",
        "source": {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": this._liftCollection()
          }
        },
        "layout": {
          "line-join": "round",
          "line-cap": "round"
        },
        "paint": {
          "line-color": "#CB7C86",
          "line-width": 3,
          "line-dasharray": [1, 2]
        },
        "minzoom": 10
      })

      // TODO Update this setHTML
      //.setHTML(`<h2 class=''>${name}</h2><p>${I18n.t('lifts.map.popup.subtitle', capacity: properties.capacity, type: I18n.t(`lifts.types.${properties.type}`))}</p>`)
      this.map.on('click', 'lifts', (ev) => {
        const properties = ev.features[0].properties
        name = properties.name

        new mapboxgl.Popup({closeButton: false})
        .setLngLat(ev.lngLat)
        .setHTML(`<h2 class='h5'>${name}</h2>`)
        .addTo(this.map)
      })

      this.map.on('mouseenter', 'lifts', () => {
        this.map.getCanvas().style.cursor = 'pointer'
      })
      this.map.on('mouseleave', 'lifts', () => {
        this.map.getCanvas().style.cursor = ''
      })
    })
  }


  _setupRestaurants() {
    this.restaurants.forEach((marker, index) => {
      const el = document.createElement('div')
      el.className = "map-marker-restaurant d-none"

      new (mapboxgl.Marker)(el).setLngLat(marker.coordinates).addTo(this.map)

      el.addEventListener('click', (event) => {
        this._handleRestaurantMarkerClick(event, marker)
        event.stopPropagation()
      })
    })

    if (this.restaurantsZoomDependant) {
      this.map.on('zoom', () => {
        const currentZoom = this.map.getZoom()
        if (!this.restaurantsVisible && currentZoom >= 16) {
          document.querySelectorAll('.map-marker-restaurant').forEach(element => element.classList.remove('d-none'))
          this.restaurantsVisible = true
        }
        if (this.restaurantsVisible && currentZoom < 16) {
          document.querySelectorAll('.map-marker-restaurant').forEach(element => element.classList.add('d-none'))
          this.restaurantsVisible = false
          document.querySelectorAll('.map-popup-restaurant').forEach(element => element.remove())
        }
      })
    } else {
      document.querySelectorAll('.map-marker-restaurant').forEach(element => element.classList.remove('d-none'))
    }
  }

  _isViewingOnSmallScreen() {
    this.map.getCanvas().height > this.map.getCanvas().width
  }

  _handleRestaurantMarkerClick(event, restaurant) {
    this._removeActivePopup()

    // Display popup (display on left for horzontal screens)
    let anchor = ''
    if (!this._isViewingOnSmallScreen()) {
      anchor = 'left'
    }
    new (mapboxgl.Popup)({anchor: anchor, className: 'map-popup-restaurant'}).setLngLat(restaurant.coordinates).setHTML(this._renderMustacheTemplate(this.restaurantPopupTemplate, restaurant)).addTo(this.map)
  }

  _renderMustacheTemplate(template, data) {
    return Mustache.render(template, data)
  }

  _removeActivePopup() {
    document.querySelectorAll('.map-marker-property').forEach(element => element.classList.remove('active'))
    document.querySelectorAll('.mapboxgl-popup').forEach(element => element.remove())
  }

  _liftCollection() {
    let collection = []

    this.lifts.forEach((lift) => {
      const feature = {
        "type": "Feature",
        "properties": {
          "name": lift.name,
          "capacity": lift.capacity,
          "type": lift.type
        },
        "geometry": {
          "type": "LineString",
          "coordinates": [
            [lift.lng_start, lift.lat_start],
            [lift.lng_end, lift.lat_end]
          ]
        }
      }
      collection.push(feature)
    })

    return collection
  }

  _refreshPropertyClustering() {
    //# console.log("Refreshing clustering. Zoom - #{@map.getZoom()}")

    document.querySelectorAll('.map-marker-property').forEach(element => element.classList.add('d-none'))
    this.map.querySourceFeatures('properties').forEach((property) => {
      if (property.properties.cluster) {
        this._renderPropertyCluster(property)
      } else {
        this._showPropertyMarker(property)
      }
    })

    // this.map.once('idle', this._refreshPropertyClustering)
  }


  _showPropertyMarker(propertySource) {
    // console.log("Showing Property Marker #{propertySource.properties.id}")
    this.propertyMarkers.get(propertySource.properties.id).element.classList.remove("d-none")
  }

  // PLACEHOLDER METHOD - This behaviour is currently handled by Mapbox.
  // Custom solution will be implemented which displays the property types ratio in a ring around the amount within the cluster
  _renderPropertyCluster(property) {
    // console.log('Rendering Property Cluster')
    //
    // Return early if the cluster is already rendered (possible implementation)
    // && !@renderedClusters.has(property.properties.cluster_id)
  }

  _handlePropertyMarkerClick(propertySource) {
    if (this.map.getZoom() < 14) {
      this.map.flyTo({
        center: propertySource.geometry.coordinates,
        zoom: 14
      })
    }

    this._openPropertyMarkerPopup(propertySource)
  }

  _openPropertyMarkerPopup(propertySource) {
    this._removeActivePopup()
    this.propertyMarkers.get(propertySource.properties.id).element.classList.add('active')

    // Display popup (display on left for horzontal screens)
    let anchor = ''
    if (!this._isViewingOnSmallScreen()) {
      anchor = 'left'
    }

    const popup = new (mapboxgl.Popup)({ anchor: anchor, className: 'map-popup-property' }).setLngLat(propertySource.geometry.coordinates).setHTML(this._renderMustacheTemplate(this.propertyPopupTemplate, propertySource.properties)).addTo(this.map)
    popup.on('close', () => {
      document.querySelectorAll('.map-marker-property').forEach(element => element.classList.remove('active'))
    })
  }

  _removeMarkers() {
    document.querySelectorAll('.map-marker-property').forEach(element => element.remove())
    this.propertyMarkers = new Map()
  }
}

//   _centerMapOnMarkers: (data) ->
//     properties = data.template_data
//     if data.count == 1
//       @map.flyTo(center: properties[0].coordinates)
//     else
//       lats = []
//       lngs = []
//       properties.forEach (marker, index) =>
//         lngs.push marker.coordinates[0]
//         lats.push marker.coordinates[1]
//
//       northWestBoundary = [Math.min.apply(null, lngs), Math.max.apply(null, lats)]
//       southEastBoundary = [Math.max.apply(null, lngs), Math.min.apply(null, lats)]
//
//       @map.fitBounds([ northWestBoundary, southEastBoundary], padding: 100)
