import I18n from 'i18n-js/index.js.erb'
import ShoppingCart from 'utils/shopping_cart'
// import { datadogRum } from '@datadog/browser-rum'

window.shoppingCart = new ShoppingCart()
document.addEventListener("turbolinks:before-render", () => {
  window.shoppingCart = new ShoppingCart()
})

import "@hotwired/turbo-rails"
import Toast from "utils/toast"
// import SwiperCore, { Autoplay } from 'swiper'
// import Swiper bundle with all modules installed
// TODO: Change this to only required modules
import Swiper from 'swiper/bundle'
// import SwiperCore, { Autoplay } from 'swiper'
// SwiperCore.use([Autoplay])
window.Swiper = Swiper
window.I18n = I18n
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle")

import "controllers"

// datadogRum.init({
//     applicationId: '641ab45d-c8dc-4ca0-b3d5-6f74a54d920f',
//     clientToken: 'pub3f6ee301d6be9fbcd1059690bb56bcc6',
//     site: 'datadoghq.com',
//     service:'vacation-niseko',
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sampleRate: 100,
//     trackInteractions: true
// })

// // import 'lazysizes'
// // import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
document.addEventListener("turbo:load", function() {
  // Register VirtualPageview to GTM
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ 'event': 'VirtualPageview' })

  // Register PageRead to GTM
  setTimeout(() => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({ 'event': 'PageRead' })
  }, 20000)

  I18n.locale = document.querySelector('body').dataset.userLocale

  var header = document.querySelector('header');
  window.addEventListener('scroll', function() {
    var scrollPosition = window.scrollY;

    if (scrollPosition >= 30) {
      header.classList.add('stuck');
    } else {
      header.classList.remove('stuck');
    }
  });

  const flashType = document.querySelector('body').dataset.flashType
  const flashMessage = document.querySelector('body').dataset.flashMessage
  if (flashType) {
    if (flashType == 'notice') {
      new Toast({type: 'success', title: 'Success', content: flashMessage })
    } else {
      new Toast({type: 'error', title: 'Error', content: flashMessage })
    }
  }

  // Close popovers on outside click
  document.querySelector('body').addEventListener('click', function(e) {
    var in_popover = e.target.closest(".popover");

    if (!in_popover) {
      var popovers = document.querySelectorAll('.popover.show');

      if (popovers[0]) {
        var triggler_selector = `[aria-describedby=${popovers[0].id}]`;

        if (!e.target.closest(triggler_selector)) {
          let the_trigger = document.querySelector(triggler_selector);
          if (the_trigger) {
            bootstrap.Popover.getInstance(the_trigger).hide();
          }
        }
      }
    }
    if (e.target.dataset.bsToggle != 'tooltip') {
      var tooltip = document.querySelector('.tooltip.show');
      if (tooltip) {
        var triggler_selector = `[aria-describedby=${tooltip.id}]`;
        let the_trigger = document.querySelector(triggler_selector);
        if (the_trigger) {
          bootstrap.Tooltip.getInstance(the_trigger).hide();
        }
      }
    }
  });

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('.js-popover-trigger'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, { sanitize: false })
  })

  if (document.querySelector('#grid_form')) {
    document.querySelector('#grid_form').querySelector('button').click()
  }

  // Handles sidebar book now widget for Roomboss properties
  const propertyDetailForm = document.querySelector(".js-form--property-detail-search")
  if (propertyDetailForm) {
    propertyDetailForm.addEventListener("submit", function(event){
      event.preventDefault()
      const data = Object.fromEntries(new FormData(event.target).entries())
      const form = document.querySelector('.form--room-search')

      if (data.checkout_at != '' && data.checkin_at != '') {
        form.elements.guests.value = data.guests
        form.elements.checkin_at.value = data.checkin_at
        form.elements.checkout_at.value = data.checkout_at
        form.elements.checkin_at.dispatchEvent(new Event('change')) // Update the datepicker UI

        document.querySelector('#book').scrollIntoView(true);
        setTimeout(() => { document.querySelector('.form--room-search button').click() }, 100)
      } else {
        new Toast({type: 'error', title: 'Error', content: 'Please select your checkin/checkout dates.' })
      }
    });
  }

  const form = document.querySelector('.form--room-search')
  if (window.location.hash == "#book" && form) {
    setTimeout(() => { document.querySelector('.form--room-search button').click() }, 200)
  }
});

document.addEventListener("turbo:before-cache", function() {
  document.getElementById('offcanvas').classList.remove('show')
  document.querySelectorAll('.modal.show').forEach(element => {
    bootstrap.Modal.getInstance(element).hide()
  })
})
