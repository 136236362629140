import { Controller } from "stimulus"

// This is used for multi selects
// TODO - Create a base class to merge all the duplicate code from these alias controllers then have each controller inherit from that
export default class extends Controller {
  forward() {
    // Return early if event has been forwarded
    if ((typeof event.detail != "undefined") && event.detail.forward) {
      return;
    }

    let filterElement = document.getElementsByName(this.element.dataset.formElement)[0];
    if (this.element.checked) {
      this.addItem(filterElement)
    } else {
      this.removeItem(filterElement)
    }
    filterElement.form.querySelector('button').click()

    let aliases = document.querySelectorAll(`[data-controller='form-filter-alias'][data-form-element='${this.element.dataset.formElement}'][data-value='${this.element.dataset.value}']`)
    Array.from(aliases).filter(alias => alias != this.element).forEach(alias => {
      alias.checked = this.element.checked
      alias.dispatchEvent(new CustomEvent('change', { detail: { forward: true } })) // Need this so that the visuals are updated
    })
  }

  // Add item to filter which accepts multiple values
  addItem(filterElement) {
    let filterArray = filterElement.value.split(",")

    if (filterArray.indexOf(this.element.value) == -1) {
      filterArray.push(this.element.value)
    }

    filterElement.value = filterArray.join()
  }

  // Remove item from filter which accepts multiple values
  removeItem(filterElement) {
    let filterArray = filterElement.value.split(",")
    let filterArrayIndex = filterArray.indexOf(this.element.value)

    if (filterArrayIndex != -1) {
      filterArray.splice(filterArrayIndex, 1)
    }

    filterElement.value = filterArray.join()
  }
}
