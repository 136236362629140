import { Controller } from "stimulus"

export default class extends Controller {
  forward() {
    // Update the element the alias represents and re-submit
    let formElement = document.getElementsByName(this.element.dataset.formElement)[0];
    formElement.value = this.element.value

    document.getElementsByName('grid[checkout_at]')[0].value = document.getElementById(this.element.dataset.elementEnd).value
    formElement.form.querySelector('button').click()

    // Update any aliases
    let aliases = document.querySelectorAll(`[data-controller='datepicker form-datepicker-alias'][data-form-element='${this.element.dataset.formElement}']`)
    Array.from(aliases).filter(alias => alias != this.element).forEach(alias => {
      var datepickerController = this.application.getControllerForElementAndIdentifier(alias, "datepicker")
      datepickerController.broadcasting = false
      if (event.detail && event.detail.checkInAt && event.detail.checkOutAt) {
        datepickerController.picker.setDateRange(event.detail.checkInAt, event.detail.checkOutAt)
      } else {
        datepickerController.picker.clearSelection()
      }
      datepickerController.broadcasting = true
    })
  }
}
