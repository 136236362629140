import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'clickable' ]

  updateInput() {
    if (event.target != this.clickableTarget) {
      this.clickableTarget.click()
    }
  }

  updateContainer() {
    this.element.classList.toggle('active', this.clickableTarget.checked)
  }
}
