import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'form', 'currency', 'language' ]

  connect() {
    this.data.set('initial-state', `${this.language}-${this.currency}`)
  }

  submit(event) {
    event.preventDefault()

    // Check if form has been changed
    if (this.data.get('initial-state') == `${this.language}-${this.currency}`) {
      $(this.element).foundation('close')
      return;
    }

    // if (hasFormChanged()) {
    //   $(this.element).foundation('close')
    //   return;
    // }

    // Register GTM CurrencySwitch Event
    if (document.querySelector('body').dataset.currency.toLowerCase() != this.currency) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'NewCurrency' : this.currency,
        'event' : 'CurrencySwitch'
      });
    }

    // Redirect user to language specific page with existing or new currency
    // TODO - Make this smarter
    // Do not redirect if only the currency has changed, instead update the session by firing a remote request then set the localStorage (?) currency which will udate all stimulus currency controllers
    let redirectUrl = `${this.languageTarget.options[this.languageTarget.selectedIndex].dataset.alternativeUrl}?currency=${this.currency}`
    // Turbolinks.visit(redirectUrl) - Not using Turbolinks as then need to update the set currency, doing a hard reload automatically handles this
    window.location.href = redirectUrl
  }

  get language() {
    return this.languageTarget.options[this.languageTarget.selectedIndex].value
  }

  get currency() {
    return this.currencyTarget.options[this.currencyTarget.selectedIndex].value
  }

  // hasFormChanged() {
  //   return this.data.get('initial-state') == `${this.language}-${this.currency}`
  // }
}
