import { Controller } from "stimulus"

export default class extends Controller {
  trigger(event) {
    this.mapController = this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='map']"), "map")

    if (this.mapController) {
      event.preventDefault()

      const instance = bootstrap.Modal.getInstance(this.mapController.element)
      if (instance) {
        instance.show()
      } else {
        new bootstrap.Modal(this.mapController.element).hide()
      }
      this.mapController.flyToProperty(this.element.dataset.id)
    }
  }
}
