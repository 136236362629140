import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "guestInput", "titleLabel" ]

  connect() {
    this.quantityAvailable = parseInt(this.element.dataset.quantityavailable)
    window.shoppingCart.on('item-added', (item) => {
      this.subtractAvailability(item)
    })

    window.shoppingCart.on('item-removed', (item) => {
      this.addAvailability(item)
    })
  }

  updateRates(event) {
    this.guestInputTarget.disabled = true
    Array.from(this.element.querySelectorAll('.available-room-type-rate button')).forEach(buttonElement => {
      buttonElement.disabled = true
    })

    let data = {
      checkin_at: this.roomTypeSearchController.formTarget.querySelector('[name="checkin_at"]').value,
      checkout_at: this.roomTypeSearchController.formTarget.querySelector('[name="checkout_at"]').value,
      discount_code: this.roomTypeSearchController.formTarget.querySelector('[name="discount_code"]').value,
      guests: this.guestInputTarget.value,
      room_id: this.element.dataset.id,
      hotel_id: this.roomTypeSearchController.element.dataset.hotelId,
      authenticity_token: this.element.dataset.authenticityToken
    };

    fetch(this.roomTypeSearchController.element.dataset.roomRatesUrl, {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        this.element.querySelector('.available-room-type-rates').innerHTML = json.content
        this.guestInputTarget.disabled = false
      })
      .catch(error => {
        new Toast({type: 'error', title: 'Error', content: 'Unfortunately there has been an error. Please try again later.' })
      })
  }

  subtractAvailability(item) {
    if (this.element.dataset.id == item.roomTypeId) {
      this.quantityAvailable--
      this.toggleAvailbility()
    }
  }

  addAvailability(item) {
    if (this.element.dataset.id == item.roomTypeId) {
      this.quantityAvailable++
      this.toggleAvailbility()
    }
  }

  toggleAvailbility() {
    if (this.quantityAvailable < 1) {
      this.disableRates()
      this.guestInputTarget.disabled = true
    } else {
      this.enableRates()
      this.guestInputTarget.disabled = false
    }
  }

  disableRates() {
    Array.from(this.element.querySelectorAll('.available-room-type-rate')).forEach(rateElement => {
      rateElement.dispatchEvent(new CustomEvent('disableRate'))
    })
  }

  enableRates() {
    Array.from(this.element.querySelectorAll('.available-room-type-rate')).forEach(rateElement => {
      rateElement.dispatchEvent(new CustomEvent('enableRate'))
    })
  }

  get roomTypeSearchController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='available-room-type-search']"), "available-room-type-search")
  }
}
