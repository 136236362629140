import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "closeWrapper", "openWrapper" ]

  open() {
    this.closeWrapperTarget.classList.add("d-none")
    this.openWrapperTarget.classList.remove("d-none")
  }
}
