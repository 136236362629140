import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  update(event) {
    this.inputTarget.value = event.target.dataset.value

    Array.from(this.element.querySelectorAll('i')).forEach((element, priority) => {
      element.classList.remove('bi-star')
      element.classList.remove('bi-star-fill')

      if (parseInt(this.inputTarget.value) > priority) {
        element.classList.add('bi-star-fill')
      } else {
        element.classList.add('bi-star')
      }
    })
  }
}
