import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bookButton", "lineItemTemplate", "list", "priceLabel", "form" ]

  connect() {
    window.shoppingCart.on('item-added', (item) => {
      this.addItem(item)
    })

    window.shoppingCart.on('item-removed', (item) => {
      this.removeItem(item)
    })
  }

  async handleSubmission(event) {
    if (event.detail.success) {
      const response = await event.detail.fetchResponse.response.json()

      // Set Data Layer Variables
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'BookingValue': response.booking_value,
        'PropertyBooked': this.formTarget.dataset.property
      })

      // Broadcast Event
      window.dataLayer.push({ 'event': response.booking_event })

      window.location.href = response.redirect_url
    } else {
      new Toast({type: 'error', title: 'Error', content: 'An unexpected error occurred. Please try again later.' })
    }
  }

  addItem(item) {
    this.listTarget.insertAdjacentHTML('beforeend', this.lineItemTemplateTarget.innerHTML)
    var lineItemElement = Array.from(this.listTarget.querySelectorAll('.shopping-cart-line-item')).pop()

    lineItemElement.dataset.id = item.id
    lineItemElement.querySelector('.shopping-cart-line-item--title').innerHTML = item.roomTypeTitle
    lineItemElement.querySelector('.shopping-cart-line-item--subtitle').innerHTML = item.title
    lineItemElement.querySelector('.shopping-cart-line-item--price span').dataset.price = item.price
    lineItemElement.querySelector('.shopping-cart-line-item--price span').dispatchEvent(new CustomEvent('currency:recalculate'))
    lineItemElement.querySelector('.shopping-cart-line-item--guests span').innerHTML = item.guests

    this.updatePrice()
    this.updateForm()
  }

  removeItem(item) {
    var lineItemElement = this.listTarget.querySelector(`[data-id='${item.id}']`)

    this.updatePrice()
    lineItemElement.remove()
    this.updateForm()
  }

  updatePrice() {
    this.priceLabelTarget.dataset.price = window.shoppingCart.totalPrice
    this.priceLabelTarget.dispatchEvent(new CustomEvent('currency:recalculate'))
  }

  updateForm() {
    var totalPriceInput = document.querySelector("#booking_total_price")
    var rateIdsInput = document.querySelector("#booking_rate_ids")
    var roomIdsInput = document.querySelector("#booking_room_ids")
    var acceptedPricesInput = document.querySelector("#booking_accepted_prices")
    var roomGuestsInput = document.querySelector("#booking_room_guests")
    var guestsInput = document.querySelector("#booking_guests")

    var totalGuests = 0
    var roomTypeIds = []
    var rateIds = []
    var acceptedPrices = []
    var roomGuests = []

    Object.keys(window.shoppingCart.items).forEach(function (key) {
      var item = window.shoppingCart.items[key]

      roomTypeIds.push(item.roomTypeId)
      rateIds.push(item.ratePlanId)
      acceptedPrices.push(item.price)
      roomGuests.push(item.guests)

      totalGuests += item.guests
    })

    totalPriceInput.value = window.shoppingCart.totalPrice
    rateIdsInput.value = rateIds.join(',')
    roomIdsInput.value = roomTypeIds.join(',')
    acceptedPricesInput.value = acceptedPrices.join(',')
    roomGuestsInput.value = roomGuests.join(',')
    guestsInput.value = totalGuests
  }
}
