import { Controller } from "stimulus"

export default class extends Controller {
  register(event) {
    if (event.detail.success) {
      var elements = this.element.elements

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'QuickSearchCheckinAt' : elements.checkin_at.value,
        'QuickSearchCheckoutAt' : elements.checkout_at.value,
        'QuickSearchGuests' : elements.guests.value,
        'QuickSearchWidget' : this.element.dataset.formQuickSearchWidget,
        'event' : 'QuickSearch'
      })
    }
  }
}
