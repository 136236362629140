import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      spaceBetween: 10,
      slidesPerView: 1,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // }// ,
      pagination: {
        el: ".swiper-pagination"//,
        // type: "fraction",
      },

      // //slidesPerView: 'auto',
      // slidesPerView: 2,
      // loop: true,
      // // loopedSlides: 22,
      // // loopAdditionalSlides: 10,
      // autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false
      // },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        }
      }
    });
  }
}
